import React, { useImperativeHandle, useRef } from "react"

import { Link } from "gatsby"

import * as styles from "../styles/menu.module.css"

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  render() {
    return (
      <div className="relative z-30">
        <div
          role="button"
          tabIndex="0"
          className={`${styles.menu} ${
            this.state.open ? `${styles.open}` : ""
          }`}
          onClick={() => this.close()}
          onKeyDown={() => this.close()}
        >
          <nav className="text-center">
            <Link
              className="text-white block uppercase font-semibold tracking-widest py-2 hover:underline"
              to="/"
            >
              Home
            </Link>
            <Link
              className="text-white block uppercase font-semibold tracking-widest py-2 hover:underline"
              to="/about-us"
            >
              About Us
            </Link>
            <Link
              className="text-white block uppercase font-semibold tracking-widest py-2"
              to="/#capabilities"
            >
              Capabilities
            </Link>
            <div className="dropdown-menu rounded-xl">
              <Link
                className="text-brand-blue block uppercase font-semibold tracking-widest pb-2 pt-4 hover:underline"
                to="/capabilities/asset-integrity"
              >
                Asset Integrity
              </Link>
              <Link
                className="text-brand-blue block uppercase font-semibold tracking-widest py-2 hover:underline"
                to="/capabilities/oil-and-gas"
              >
                Oil &amp; Gas
              </Link>
              <Link
                className="text-brand-blue block uppercase font-semibold tracking-widest pb-4 pt-2 hover:underline"
                to="/capabilities/renewables"
              >
                Renewables
              </Link>
            </div>
            <Link
              className="text-white block uppercase font-semibold tracking-widest py-2 hover:underline"
              to="/sheq"
            >
              SHEQ
            </Link>
            <Link
              className="text-white block uppercase font-semibold tracking-widest py-2 hover:underline"
              to="/about-us#why-asp"
            >
              Why Work With Us?
            </Link>

            <Link
              className="text-white block uppercase font-semibold tracking-widest py-2 mb-2 hover:underline"
              to="/careers"
            >
              Careers
            </Link>
            <Link className="btn" to="/contact-us">
              Contact Us
            </Link>
          </nav>
        </div>
      </div>
    )
  }

  close() {
    this.setState({ open: false })
  }

  open() {
    this.setState({ open: true })
  }
}

export default React.forwardRef((props, ref) => {
  const menuRef = useRef()

  useImperativeHandle(ref, () => ({
    open() {
      menuRef.current.open()
    },
  }))

  return <Menu ref={menuRef} {...props} />
})

import React from "react"
import Layout from "../components/Layout"

export default function COVID19() {
  return (
    <Layout>
      <section class="hero px-4 bg-brand-blue flex items-center">
        <div class="container mx-auto">
          <div class="hero__content text-center">
            <h1 class="text-white pb-4 relative">
              Thank you!
              <span className="border-b-2 border-brand-orange inline-block h-1 w-24 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
            </h1>
          </div>
        </div>
      </section>
      <section className="px-4 py-24">
        <div className="container mx-auto">
          <p>
            Thank you for your message! A CAST representative will be in touch
            with you shortly.
          </p>
        </div>
      </section>
    </Layout>
  )
}

import { Link } from "gatsby"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Footer() {
  return (
    <>
      <div className="footer-top bg-brand-blue">
        <div className="footer-top__inner bg-white"></div>
      </div>
      <footer className="site-footer px-4 bg-brand-blue pt-24">
        <div className="sm:px-16 md:grid md:grid-cols-4 md:gap-4">
          <div className="footer-widget">
            <h5 className="text-white uppercase font-semibold mb-6 tracking-widest">
              Links
            </h5>
            <ul className="mb-24 md:mb-0 leading-7">
              <li>
                <Link className="text-white hover:underline" to="/about-us">
                  About Us
                </Link>
              </li>
              <li>
                <Link className="text-white hover:underline" to="/sheq">
                  SHEQ
                </Link>
              </li>
              <li>
                <Link
                  className="text-white hover:underline"
                  to="/about-us#why-asp"
                >
                  Why Work With Us?
                </Link>
              </li>
              <li>
                <Link className="text-white hover:underline" to="/careers">
                  Careers
                </Link>
              </li>
              <li>
                <Link className="text-white hover:underline" to="/contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-widget">
            <h5 className="text-white uppercase font-semibold mb-6 tracking-widest">
              Capabilities
            </h5>
            <ul className="mb-24 leading-7">
              <li>
                <Link
                  className="text-white hover:underline"
                  to="/capabilities/asset-integrity"
                >
                  Asset Integrity
                </Link>
              </li>
              <li>
                <Link
                  className="text-white hover:underline"
                  to="/capabilities/oil-and-gas"
                >
                  Oil &amp; Gas
                </Link>
              </li>
              <li>
                <Link
                  className="text-white hover:underline"
                  to="/capabilities/renewables"
                >
                  Renewables
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-widget">
            <h5 className="text-white uppercase font-semibold mb-6 tracking-widest">
              Follow Us
            </h5>
            <div className="footer__socials mb-24"></div>
          </div>
          <div className="footer-widget">
            <img
              className="mb-24 w-48"
              src="/images/cast-logo.svg"
              alt="CAST Rope Access"
            />
          </div>
        </div>
        <div className="copyright pb-12 text-center">
          <p className="text-sm text-white">
            &copy; 2022 CAST Rope Access. All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  )
}

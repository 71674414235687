import { Link } from "gatsby"
import React from "react"
import Navigation from "./Navigation"

export default function Header() {
  return (
    <>
      <header className="site-header h-28 px-4 py-4 -mb-28 relative z-50">
        <div className="container mx-auto flex justify-between items-center">
          <Link className="inline-block" to="/">
            <img
              className="h-20"
              src="/images/cast-logo.svg"
              alt="CAST Rope Access"
            />
          </Link>
          <div className="site-header__triggers flex items-center">
            {/* <button className="site-header__search-button inline-block mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button> */}
            <Navigation />
          </div>
        </div>
      </header>
    </>
  )
}
